import { http } from './config'
import authHeader from './auth-header';

export default {

    lista: (empresa, situacao) => {
       /*
        const st = http.get('contratos', { headers: authHeader() })
        const s = st.then(value => {
            alert.log("this gets called after the end of the main stack. the value received and returned is: " + value);
            return value;
        });
        */
        return http.get('contratos/'+empresa + '/'+ situacao, { headers: authHeader() })
        //return axios.get(API_URL + 'user', { headers: authHeader() });

    },

    consulta_tem: (cpf) => {
         return http.get('consulta-loja/'+cpf, { headers: authHeader() }) 
     },


     consulta_cartao_status: (cpf, cartao) => {
        return http.get('consulta-cartao-status/'+cpf+'/'+cartao, { headers: authHeader() }) 
    },
     

     consulta_tem_lote: (dataI, dataF) => {
        return http.get('/consulta-lote-loja', {
            params: {
                'data-inicial': dataI,
                'data-final': dataF
            },
            headers: authHeader()
        });
    },

/*
     consulta_tem_lote: (dataI, dataF) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
          };

          return http.get('/consulta-lote-loja', {
            params: params,
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqu
          });

          
    },
*/

     ativar_cartao: (cpf, cartao) => {
        return http.get('novo-status/'+cpf+"/"+cartao, { headers: authHeader() }) 
    },

    suspender_cartao: (cpf, cartao) => {
        return http.get('novo-status-suspender/'+cpf+"/"+cartao, { headers: authHeader() }) 
    },

    cancelar_cartao: (cpf, cartao) => {
        return http.get('novo-status-cancelar/'+cpf+"/"+cartao, { headers: authHeader() }) 
    },

    adesao_cartao: (cpf, cartao) => {
        return http.get('adesao/'+cpf+"/"+cartao, { headers: authHeader() }) 
    },
 

    abrir: (id) => {
        return http.get('contrato', id)
    },

    update: (id) => {
        return http.get('update/'+ id, { headers: authHeader() })
    },

    cancela: (id) => {
        return http.get('cancela/'+ id, { headers: authHeader() })
    },

    recupera: (id) => {
        return http.get('recupera/'+ id, { headers: authHeader() })
    },


    salvar: (contrato) => {
        return http.post('contrato', contrato)
    },

    downloadFicha: (id) => {
        const params = new URLSearchParams([['id', id]])
        return http.get('download_ficha', { params }, { responseType: 'blob' },
        )
    },

    downloadZip: (id) => {
        const params = new URLSearchParams([['id', id]])
        return http.get('download_zip', { params }, { responseType: 'blob' },
        )
    },

}
