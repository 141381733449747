<template>
  <v-container fluid tag="section">
    <base-material-card
      icon="mdi-check"
      title="Consultar Cartões TEM"
      class="px-5 py-3"
    >
      <v-container>
        <v-form @submit.prevent="consulta_tem">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="dataInicial"
                label="Data Inicial"
                outlined
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="dataFinal"
                label="Data Final"
                outlined
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn :loading="load_consulta" @click="consulta_tem" color="primary" class="mt-3">
            Consultar
          </v-btn>
        </v-form>

        <!-- Exibe uma mensagem se a resposta for nula -->
        <v-alert v-if="!cartoes.length && mensagem" type="error" class="mt-5">
          {{ mensagem }}
        </v-alert>

        <!-- Data Table com resultados -->
        <v-data-table
          v-if="cartoes.length"
          :headers="headers"
          :items="cartoes"
          class="mt-5"
        >
          <template v-slot:item.acao="{ item }">
            <v-progress-circular
              v-if="item.status === 'Carregando...'"
              :size="24"
              :width="2"
              indeterminate
              color="blue"
            ></v-progress-circular>

            <v-btn  :loading="load_acao"  v-if="item.status === 'SUSPENSO'" small @click="ativarCartao(item)" color="green">Ativar</v-btn>
            <v-btn style="margin-left:2px" :loading="load_acao" v-if="item.status === 'ATIVO' || item.status === 'SUSPENSO'" small @click="cancelarCartao(item)" color="red">Cancelar</v-btn>
            <v-btn style="margin-left:2px" :loading="load_acao" v-if="item.status === 'ATIVO'"  small @click="suspenderCartao(item)" color="orange">Suspender</v-btn>
            <v-btn :loading="load_acao" v-if="item.status === 'PENDENTE'" small @click="AdesaoCartao(item)" color="orange">Adesão</v-btn>
          </template>

        </v-data-table>
      </v-container>
    </base-material-card>
  </v-container>
</template>


<script>
  import Contrato from '../../../services/contrato';
  import axios from 'axios';
  import authHeader from '../../../services/auth-header';
  import config from '../../../services/config';

  export default {
    data: () => ({
      dataInicial: '',
      dataFinal: '',
      mensagem: '',
      load_consulta: false,
      load_acao: false,
      cartoes: [], // Armazena os cartões retornados
      headers: [
        { text: 'Cartão', value: 'cartao' },
        { text: 'Contrato', value: 'contrato' },
        { text: 'CPF', value: 'cpf' },
        { text: 'Nome', value: 'nome' },
        { text: 'Status', value: 'status' },
        { text: 'Ações', value: 'acao', sortable: false },
      ],
    }),

    mounted() {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        this.dataInicial = formattedDate;
        this.dataFinal = formattedDate;
      },
    methods: {



      async consulta_tem() {
         

            // Valida se as datas foram preenchidas
        if (!this.dataInicial || !this.dataFinal) {
          this.mensagem = 'Por favor, preencha ambas as datas.';
          this.load_consulta = false;
          return;
        }

                // Verifica se o intervalo de datas é de no máximo 31 dias
        const dataInicial = new Date(this.dataInicial);
        const dataFinal = new Date(this.dataFinal);
        const diffTime = Math.abs(dataFinal - dataInicial);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 31) {
          alert('O intervalo máximo permitido é de 31 dias.');
          this.load_consulta = false;
          return;
        }
        
        this.load_consulta = true;
        this.mensagem = '';

          try {
            // Chama o método de consulta passando as datas como parâmetros
            const response = await Contrato.consulta_tem_lote(this.dataInicial, this.dataFinal, {
              headers: authHeader(),

            });

            // Atribui os dados retornados à variável cartoes
            this.cartoes = response.data;
            this.atualizarStatusCartoes();

          } catch (e) {
            this.mensagem = 'Erro ao consultar os cartões.';

          } finally {
            this.load_consulta = false;
          }
        },


        async atualizarStatusCartoes() {
            for (let cartao of this.cartoes) {
              try {
                // Faz a consulta para obter o novo status do cartão
                const response = await Contrato.consulta_cartao_status(cartao.cpf, cartao.cartao.replace(/ /g, ""));
                
                // Atualiza o status do cartão na lista
                cartao.status = response.data.statusCartao;
              } catch (e) {
                console.error(`Erro ao atualizar o status do cartão ${cartao.cartao}:`, e);
              }
            }

            this.load_consulta == false;
          },

      async ativarCartao(cartao) {

        const confirmed = confirm(`Você tem certeza que deseja ativar o cartão ${cartao.cartao}?`);
        if (!confirmed) return;
        this.load_acao = true;

        try {
          const response =  await Contrato.ativar_cartao(cartao.cpf, cartao.cartao.replace(/ /g, ""));
          cartao.status = response.data.statusCartao; // Supondo que a resposta contém o status atualizado
        } catch (e) {
          alert('Erro ao ativar o cartão.'+ e);
          this.load_acao = false;

        }finally {
            this.load_acao = false;
          }
        },
    


      async cancelarCartao(cartao) {

        const confirmed = confirm(`Você tem certeza que deseja cancelar o cartão ${cartao.cpf}?`);
        if (!confirmed) return;
        this.load_acao = true;

        try {
          const response = await Contrato.cancelar_cartao(cartao.cpf, cartao.cartao.replace(/ /g, ""));
          cartao.status = response.data.statusCartao; // Supondo que a resposta contém o status atualizado
        } catch (e) {
          alert('Erro ao cancelar o cartão.');
          this.load_acao = false;

        }finally {
            this.load_acao = false;
          }
      },

      async suspenderCartao(cartao) {

        const confirmed = confirm(`Você tem certeza que deseja suspender o cartão ${cartao.cartao}?`);
        if (!confirmed) return;
        this.load_acao = true;

        try {
          const response = await Contrato.suspender_cartao(cartao.cpf, cartao.cartao.replace(/ /g, ""));
          cartao.status = response.data.statusCartao; // Supondo que a resposta contém o status atualizado
        } catch (e) {
          alert('Erro ao suspender o cartão.');
          this.load_acao = false;

        }finally {
            this.load_acao = false;
          }
      },

      async AdesaoCartao(cartao) {
        this.load_consulta = true;
        this.mensagem = '';
          try {
            // Chama o método para ativar o cartão
            const response = await Contrato.adesao_cartao(cartao.cpf, cartao.cartao.replace(/ /g, ""));
            cartao.status = response.data.statusCartao; // Supondo que a resposta contém o status atualizado

          } catch (e) {
            this.mensagem = 'Erro ao fazer adesão do cartão.';
            this.load_acao = false;

          } finally {
            this.load_acao = false;
            this.load_consulta == false;

          }
    
      },

      async consulta_cartao_status(cartao) {
        this.load_consulta = true;
          try {
            const response = await Contrato.consulta_cartao_status(cartao.cpf, cartao.cartao.replace(/ /g, ""));
            if (response.data) {
              this.cartao = response.data;
              this.mensagem = ''; // Limpa a mensagem de erro, se houver
            } else {
              this.cartao = null;
              this.mensagem = 'Nenhuma informação encontrada para a consulta.';
            }
            this.load_consulta = false;

          } catch (e) {
            if (e.response && e.response.status === 401) {
              this.$store.dispatch('auth/logout');
              this.$router.push('/restrito/login');
            } else {
              this.mensagem = 'Erro ao realizar a consulta. Tente novamente.';
            }
            this.cartao = null; // Limpa os dados do cartão em caso de erro
            this.load_consulta = false;

      }
    },

    }
  }
</script>
